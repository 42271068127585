
.profile-page {
  padding: 20px;
}

.profile-box {
  background-color: #f8f9fa;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.profile-header {
  display: flex;
  align-items: center;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.profile-info h2 {
  margin: 0;
}

.clickable {
  color: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.clickable:hover {
  color: #C41230;
  text-decoration: underline;
}

.payment-details,
.vehicle-details {
  display: flex;
  flex-direction: column;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.float-right {
  float: right;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.ml-3 {
  margin-left: 1rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.coupon-section {
  display: flex;
  align-items: center;
}

.coupon-section p {
  margin-right: 10px;
  margin-bottom: 0;
}

.coupon-section .form-control {
  display: inline-block;
  width: auto;
  margin-right: 10px;
}

.coupon-section .clickable {
  cursor: pointer;
}