.modal-custom {
    max-width: 600px;
    margin: 1.75rem auto;
}

.modal-content {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
}

.modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    border-top: none;
}
/* .form-control{
    padding: 0;
} */
.modal-body select {
    padding-left: 2px !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
}
.form-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

label {
    flex: 1;
    margin-right: 10px;
    text-align: left;
}

select,
input {
    flex: 2;
    padding: 0;
}

.btn-custom {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    font-size: 1rem;
}

.btn-cancel {
    background-color: #C41230;
    color: #fff;
    border: none;
}

.btn-cancel:hover {
    background-color: #c9302c;
}

.btn-confirm {
    background-color: #C41230;
    color: #fff;
    border: none;
}

.btn-confirm:hover {
    background-color: #C41230;
}

.btn-confirm:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
}