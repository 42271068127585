.modal-custom {
    max-width: 800px; /* Increase this value to make the modal wider */
    margin: 1.75rem auto;
}

.modal-content {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
}

.modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    border-top: none;
}

.btn-custom {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    font-size: 1rem;
}

.btn-cancel {
    background-color: #C41230;
    color: #fff;
    border: none;
}

.btn-cancel:hover {
    background-color: #c9302c;
}

.btn-confirm {
    background-color: #C41230;
    color: #fff;
    border: none;
}

.btn-confirm:hover {
    background-color: #C41230;
}

.btn-confirm:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
}

.report-history-link {
    cursor: pointer;
    color: #000;
    font-size: 1rem;
    text-decoration: none;
    transition: color 0.3s ease;
    position: relative;
    display: inline-block;
}

.report-history-link::after {
    position: absolute;
    right: -20px;
    font-size: 1rem;
    color: inherit;
    transition: color 0.3s ease;
}

.report-history-link:hover {
    color: #C41230;
    text-decoration: underline;
}

.report-history-link:hover::after {
    color: #C41230;
}

/* Container for the spot cards */
.spot-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* Gap between rows */
    margin-right: 1.5rem;
}

/* Row of spot cards */
.spot-row {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    /* Gap between columns */
    flex-wrap: wrap;
}

/* Each spot card */
.spot-col {
    flex: 1 0 15%;
    /* Ensure each spot takes up an even portion of the row */
    box-sizing: border-box;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .spot-col {
        flex: 1 0 23%;
        /* Adjust for smaller screens */
    }
}

@media (max-width: 992px) {
    .spot-col {
        flex: 1 0 31%;
        /* Adjust for smaller screens */
    }
}

@media (max-width: 768px) {
    .spot-col {
        flex: 1 0 48%;
        /* Adjust for smaller screens */
    }
}

@media (max-width: 576px) {
    .spot-col {
        flex: 1 0 100%;
        /* Adjust for smaller screens */
    }
}