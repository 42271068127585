/* reportManager.css */

.calendar-container {
    margin-top: 20px;
    margin-bottom: 20px;
}

.report-container {
    display: flex;
    flex-direction: column;
}

.section {
    margin-bottom: 20px;
}

.report-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.report-col {
    flex: 1;
    padding: 10px;
}

.report-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure card takes the full height available */
}

.report-card.reported {
    border-color: #f00;
    background-color: #ffe6e6;
}

.report-image {
    width: 100%;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.btn {
    margin-top: auto; /* Push button to the bottom of the card body */
    margin-right: 0; /* Remove any right margin */
}

.btn-primary {
    background-color: #007bff;
    color: white;
}

.btn-secondary {
    background-color: #6c757d;
    color: white;
}

.btn-success {
    background-color: #28a745;
    color: white;
}

.mt-2 {
    margin-top: 10px;
}

.pagination {
    display: flex;
    justify-content: center;
}
.card-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}