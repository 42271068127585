html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.container {
    display: flex;
    min-height: 100%;
}

.col-md-7 {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.col-md-7 form {
    width: 60%;
}

.col-md-5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    text-align: center;
    background: linear-gradient(to bottom, #C41230 19%, #9E2020 87%);
}

.image-fluid {
    max-width: 10%;
    height: auto;
}

.row.mb-3 {
    padding-top: 1rem;
}


.reserveParking {
    font-weight: bold;
}

.btn.btn-primary {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    background-color: #C41230;
    width: 20rem;
    border-radius: 5px;
    border: none;
    font-weight: 500;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.btn.btn-primary:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    background-color: #C41230;
}

.button-container {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    height: 30%;
}

.sign-in-link {
    margin-left: -2rem;
    color: #999
}

.sign-in-link:hover {
    text-decoration: underline;
    cursor: pointer;
    color: #C41230;
}

.text-error {
    font-size: 0.8rem;
    color: #d9534f;
    margin-top: 0.5rem;
}

.signin-text-error {
    font-size: 0.8rem;
    color: #d9534f;
    margin-top: 0.5rem;
}

.forgot-username-link {
    padding-left: 16rem;
    font-size: small;
    color: #999
}

.forgot-username-link:hover {
    text-decoration: underline;
    cursor: pointer;
    color: #C41230;
}

.tartan-parking {
    padding-left: 2rem;
    font-size: 30px;
    font-weight: bold;
}


.heading-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 75%;
}

.getYouStarted {
    padding-bottom: 1rem;
    font-weight: bold;
    margin-left: 4rem;
}