.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 60px;
}

.modal.show {
    display: block;
}

.modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 400px;
    border-radius: 10px;
}

.modal-header h2 {
    margin: 0;
    font-size: 24px;
}

.modal-body {
    margin: 20px 0;
}

.modal-body label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
}

.modal-body label span {
    font-weight: normal;
    font-size: 12px;
    color: gray;
}

.modal-body select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.price-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
}

.price-info button {
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
}

.modal-footer {
    display: flex;
    justify-content: space-between;
}

.modal-footer button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
}

.modal-footer .cancel {
    background-color: lightgray;
    color: black;
}

.modal-footer .confirm {
    background-color: #C41230;
    color: white;
}