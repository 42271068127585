.history-trans-container {
    margin-top: 4rem;
    margin-right: 6rem;
}

.history-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin: 0.5rem 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px;
    margin-right: 10px;
}

.history-card:hover {
    background-color: #f5f5f5;
}

.history-card-date,
.history-card-info,
.history-card-price {
    flex: 1;
}

.history-card-info {
    padding: 0 1rem;
}

.history-card-price {
    text-align: right;
}

.modal-button {
    width: 48%;
    margin: 1%;
}

.modal {
    z-index: 1050;
    /* Ensure it's higher than other elements */
}

.modal-backdrop {
    z-index: 1040;
}

