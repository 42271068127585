.park-now {
    background-color: #C41230;
    border: none;
    color: white;
    padding: 10px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    width: 350px;
    border-radius: 4px;
    font-weight: bold;
}

.main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* .active-trans-container {
    margin-top: 4rem;
    margin-right: 6rem;
} */

.active-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-direction: column;
    width: 100%;
}

.active-card-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.active-card-info h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.active-card-location {
    color: #5A5A5A;
    margin-bottom: 20px;
}

.active-card-timer-container {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.active-card-timer {
    font-size: 1rem;
    margin-bottom: 20px;
    text-align: center;
    flex: 1;
}

.active-card-time {
    font-size: 2rem;
    font-weight: bold;
}

.active-card-time-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.active-card-actions {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.active-card-report-violation {
    color: #C41230;
    cursor: pointer;
    margin-bottom: 10px;
}

.active-card-report-violation:hover {
    text-decoration: underline;
}

.btn-danger {
    background-color: #C41230;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.modal {
    display: block;
    position: fixed;
    z-index: 1050;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    border-radius: 10px;
    z-index: 1060;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e9ecef;
}

.modal-body {
    padding: 20px;
}

.modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.modal-footer button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
}

.modal-footer .cancel {
    background-color: lightgray;
    color: black;
}

.modal-footer .confirm {
    background-color: #C41230;
    color: white;
}

.col-md-9 {
    margin-left: 22rem;
}